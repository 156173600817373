import SetStatus from "@/containers/set-status";
import RestoreBtn from "@/components/restore-btn";
import {mapGetters} from "vuex";
import CardNotes from "@/containers/card-notes";
import NeoAnalyse from "@/components/analyse";

export default {
    name: "personal-details-card",
    components: {
        "neo-set-status": SetStatus,
        "neo-restore-btn": RestoreBtn,
        "neo-card-notes": CardNotes,
        "neo-analyse": NeoAnalyse,
    },
    props: {
        card: {
            type: Object,
        },
        showRevert: {
            type: Boolean,
            default: false,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        tab: {
            type: String,
            default: "personalDetails",
        },
        showNotes: {
            type: Boolean,
            default: true,
        },
        restore: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isEdit: false,
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getEntitiesRelationsAttributesData"]),
        checkStatus() {
            let stat = {IRRELEVANT: false, POTENTIAL: false, CONFIRMED: false};
            if ("status" in this.card && this.card.status) {
                stat[this.card.status] = true;
            }
            return stat;
        },
    },
    mounted() {},
    methods: {
        toggleEdit() {
            const eid = this.card?.entity_type?._id;
            const item = this.getEntitiesRelationsAttributesData.entities.find((e) => e._id == eid);
            this.$store.dispatch("setSelectedEntityData", {
                datatype: "entity",
                all_data: this.card,
                editEntity: true,
                entity: item,
                text: "",
                userDefined: true,
            });

            this.$store.dispatch("showAddEntityPanel");
        },
        auto_grow(e) {
            let element = e.target;
            element.style.height = "0px";
            element.style.height = element.scrollHeight + "px";
        },
        onStatChanges(status) {
            this.card.status = status;
            this.$emit("change", this.card);
        },
        popIrrelevantData() {
            delete this.card.confidence_score;
            this.$emit("change", {...this.card, status: null, tab: this.tab});
        },
    },
};
