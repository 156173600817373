import PersonalDetailsCard from "@/containers/cards/personal-details";
import Toggle from "@/components/toggle";
import {mapGetters} from "vuex";
import axios from "@/axios";

export default {
    name: "neo-personal-details-tab",
    components: {
        // "neo-personal-entry": PersonalEntry
        "personal-details-card": PersonalDetailsCard,
        "neo-toggle": Toggle,
    },
    props: {
        content: Array,
        showNav: {
            type: Boolean,
            default: false,
        },
        removedEntity: {
            type: Array,
            default: function () {
                return ["social_profile", "volunteering_roles", "wider_evidence_skills", "charitable_work", "active_media", "search", "passive_media"];
            },
        },
        showNotes: {
            type: Boolean,
            default: false,
        },
        entities: {
            type: Array,
            default: () => [],
        },
    },
    // props: ["content"],
    data() {
        return {
            selectedTab: 0,
            // showNotes: false
        };
    },
    filters: {
        capitalize: function (value) {
            if (!value) return "";
            return value
                .split("_")
                .map((val) => {
                    return val.charAt(0).toUpperCase() + val.slice(1);
                })
                .join(" ");
        },
    },
    computed: {
        ...mapGetters(["getEntitiesRelationsAttributesData", "getCasePersonalData", "getReadOnlyMode", "getNodesInfo"]),
        classObject: function () {
            return {
                active: this.isActive && !this.error,
                "text-danger": this.error && this.error.type === "fatal",
            };
        },

        filteredData() {
            let dataMap = {};
            this.content.forEach((item) => {
                if (!this.removedEntity.includes(item.entity_identifier)) {
                    if (!(item.entity_identifier in dataMap)) {
                        dataMap[item.entity_identifier] = [];
                    }
                    dataMap[item.entity_identifier].push(item);
                }
            });

            let dispStructure = [];

            let index = 0;
            // let navTabs = this.$store.getters.getPersonalDetailsTabs.filter(el => el.key in dataMap);
            let navTabs = this.$store.getters.getPersonalDetailsTabs.filter((el) => !this.removedEntity.includes(el.key) && el.enable);
            let dataMapClone = {};

            navTabs.forEach((ele) => {
                dataMapClone[ele.key] = this.content.filter((e) => (ele.type == "entity" ? ele.mapper.find((n) => n.map_id == e.entity_type._id) : ele.entity.find((n) => n.map_id == e.entity_type._id)));
            });
            navTabs.forEach((tab) => {
                if (dataMapClone[tab.key] && dataMapClone[tab.key].length) {
                    dispStructure.push({
                        type: "header",
                        value: tab.value,
                        key: tab.key,
                        index: index,
                        entity_type: tab.entity_type,
                        mapper: tab.mapper,
                        entity: tab.entity,
                        doc_type: tab.type,
                        enable: tab.enable,
                    });
                    dataMapClone[tab.key].forEach((item) => {
                        dispStructure.push({
                            type: "card",
                            value: item,
                        });
                    });
                    index = index + 1;
                } else {
                    dispStructure.push({
                        type: "header",
                        value: tab.value,
                        key: tab.key,
                        index: index,
                        isEmpty: true,
                        entity_type: tab.entity_type,
                        mapper: tab.mapper,
                        entity: tab.entity,
                        doc_type: tab.type,
                        enable: tab.enable,
                    });
                }
            });

            const entityData = this.entities.length == 1 && this.getEntitiesRelationsAttributesData?.entities.find((n) => n._id == this.entities[0]?.entity_type?._id);
            if (entityData) {
                const stateNames = navTabs.filter((n) => n?.entity_type?.find((e) => e.entity_id == entityData.parent_id));
                const stateStructure = dispStructure.filter((n) => n.type === "card" || (n.type === "header" && n?.entity_type?.find((e) => e.entity_id == entityData.parent_id)));
                navTabs = stateNames;
                dispStructure = stateStructure;
            }
            if (!this.showNav) {
                dispStructure = dispStructure.filter((n) => !n.isEmpty);
            }
            return {names: navTabs, data: dispStructure};
        },
    },
    created() {
        const viewElement = document.getElementById("view");
        if (viewElement) {
            viewElement.addEventListener("scroll", this.handleScroll);
        }
    },
    destroyed() {
        const viewElement = document.getElementById("view");
        if (viewElement) {
            viewElement.removeEventListener("scroll", this.handleScroll);
        }
    },
    mounted() {},
    methods: {
        handleScroll(event) {
            if (this.$refs && this.$refs.sectionHeader) {
                this.$refs.sectionHeader.forEach((chEl, i) => {
                    let rect = chEl.getBoundingClientRect();
                    if (rect.top < 170 && rect.top > 120) {
                        this.selectedTab = i;
                    }
                });
            }
        },
        goto(val, tab) {
            var element = document.getElementById(val);
            // element.scrollIntoView({ behavior: 'smooth', block:'start' })
            const scrollElement = document.getElementById("view");
            if (element)
                scrollElement.scrollTo({
                    top: scrollElement.scrollTop + (element.getBoundingClientRect().top - 135),
                    behavior: "smooth",
                });
            this.selectedTab = tab;
        },
        onCardChange(item) {
            this.$emit("dataChanges", item, item);
        },
        handleAddEntity(value) {
            const entityData = this.entities[0];

            if (entityData) {
                const stateNames = value.doc_type === "entity" ? this.getEntitiesRelationsAttributesData.entities.find((n) => value.mapper.find((ele) => ele.map_id == n._id)) : this.getEntitiesRelationsAttributesData.entities.find((n) => value.entity.find((ele) => ele.map_id == n._id));

                this.$store.dispatch("setSelectedEntityData", {
                    datatype: "entity",
                    editEntity: false,
                    related_to: entityData,
                    entity: stateNames,
                    text: "",
                    userDefined: true,
                });

                this.$store.dispatch("showAddEntityPanel");
            }
        },
        openExtension(payload) {
            if (window.chrome)
                chrome?.runtime?.sendMessage(this.$store.getters.getExtensionID, payload, (response) => {
                    setTimeout(this.onClose, 1000);
                });
        },
        getEmployment(level, employment = []) {
            if (level === "L2" || level === "L3") {
                return `<table width="100%" cellpadding="9" cellspacing="1" style="table-layout: fixed; width: 100%"> <colgroup><col width="587"> </colgroup>
                ${
                    employment.length > 0
                        ? employment
                              .map(
                                  (ele) => `
                <thead>
                <tr> <td width="587" height="6" bgcolor="#0070c0" style="background: #0070c0"><p class="western" align="center" style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;"><b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;    word-break: break-all;;">${ele.entity}(-)</b></p> </td> </tr> </thead> <tbody> <tr> <td width="587" height="6"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;    word-break: break-all;">&lt;Written evidence&gt;</p></td> </tr> </tbody>`
                              )
                              .join("")
                        : `<thead>
                    <tr> <td width="587" height="6" bgcolor="#0070c0" style="background: #0070c0"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;" class="western" align="center"><b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;    word-break: break-all;">No information identified</b></p> </td> </tr> </thead> <tbody> <tr> <td width="587" height="6"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;    word-break: break-all;">&lt;Written evidence&gt;</p></td> </tr> </tbody>`
                }
                </table>`;
            } else {
                return `<table style="table-layout: fixed; width: 100%" width="100%" cellpadding="9" cellspacing="2"> <colgroup><col width="184"> <col width="213"> <col width="171"> </colgroup> <thead> <tr> <td colspan="3" width="597" height="21" bgcolor="#0070c0" style="background: #0070c0"><p class="western" align="center" style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;"> <b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;">Employment History</b></p> </td> </tr> <tr> <td width="184" height="28" bgcolor="#0070c0" style="background: #0070c0"><p class="western" align="center" style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;"> <b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;">Company</b></p> </td> <td width="213" bgcolor="#0070c0" style="background: #0070c0"><p class="western" align="center" style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;"> <b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;">Role</b></p> </td> <td width="171" bgcolor="#0070c0" style="background: #0070c0"><p class="western" align="center" style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;"> <b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;">Dates of Employment</b></p> </td> </tr> </thead>
                <tbody>
                ${
                    employment.length > 0
                        ? employment
                              .map(
                                  (ele) =>
                                      `<tr> <td width="184" height="21" bgcolor="#E7E6E6" style="background: #E7E6E6"><p class="western" style="font-size:11.0pt !important;font-family:&quot;Lato&quot;,sans-serif !important;    word-break: break-all;" align="center">${ele.entity}</p> </td> <td width="213" bgcolor="#E7E6E6" style="background: #E7E6E6"><p class="western" align="center" style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;    word-break: break-all;">&nbsp;</p> </td> <td width="171" bgcolor="#E7E6E6" style="background: #E7E6E6;font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;    word-break: break-all;"><p class="western" align="center" style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;">&nbsp;</p> </td> </tr> `
                              )
                              .join("")
                        : `<tr> <td colspan="3" width="184" height="21" bgcolor="#E7E6E6" style="background: #E7E6E6"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;    word-break: break-all;" class="western" align="center">No information identified</p> </td>`
                }
                </tbody>
                </table>`;
            }
        },
        getEducation(level, education = []) {
            if (level === "L2" || level === "L3") {
                return `<table width="100%" cellpadding="9" cellspacing="1" style="table-layout: fixed; width: 100%"> <colgroup><col width="587"> </colgroup>
                ${
                    education.length > 0
                        ? education
                              .map(
                                  (ele) => `
                <thead>
                <tr> <td width="587" height="6" bgcolor="#0070c0" style="background: #0070c0"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;" class="western" align="center"><b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;word-break: break-all;">${ele.entity}(-)</b></p> </td> </tr> </thead> <tbody> <tr> <td width="587" height="6"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;word-break: break-all;">&lt;Written evidence&gt;</p></td> </tr> </tbody>`
                              )
                              .join("")
                        : `<thead>
                <tr> <td width="587" height="6" bgcolor="#0070c0" style="background: #0070c0"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;" class="western" align="center"><b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;word-break: break-all;">No information identified</b></p> </td> </tr> </thead> <tbody> <tr> <td width="587" height="6"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;word-break: break-all;">&lt;Written evidence&gt;</p></td> </tr> </tbody>
                        `
                }
                </table>`;
            } else {
                return `<table width="100%" cellpadding="9" cellspacing="2" style="table-layout: fixed; width: 100%"> <colgroup><col width="184"> <col width="213"> <col width="171"> </colgroup> <thead> <tr> <td colspan="3" width="597" height="21" bgcolor="#0070c0" style="background: #0070c0"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;" class="western" align="center"> <b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;">Education History</b></p> </td> </tr> <tr> <td width="184" height="28" bgcolor="#0070c0" style="background: #0070c0"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;" class="western" align="center"> <b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;">Institution</b></p> </td> <td width="213" bgcolor="#0070c0" style="background: #0070c0"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;" class="western" align="center"> <b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;">Course</b></p> </td> <td width="171" bgcolor="#0070c0" style="background: #0070c0"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;color:#ffffff;" class="western" align="center"> <b style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;">Dates of Attendance</b></p> </td> </tr> </thead>
                <tbody>
                ${
                    education.length > 0
                        ? education
                              .map(
                                  (ele) =>
                                      `<tr> <td width="184" bgcolor="#E7E6E6" style="background: #E7E6E6"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;word-break: break-all;" class="western" align="center">${ele.entity}</p> </td> <td width="213" bgcolor="#E7E6E6" style="background: #E7E6E6"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;word-break: break-all;" class="western" align="center">&nbsp;</p> </td> <td width="171" bgcolor="#E7E6E6" style="background: #E7E6E6"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;word-break: break-all;" class="western" align="center">&nbsp;</p> </td> </tr> `
                              )
                              .join("")
                        : `<tr> <td colspan="3" width="184" bgcolor="#E7E6E6" style="background: #E7E6E6"><p style="font-size:11.0pt !important;line-height:120%;font-family:&quot;Lato&quot;,sans-serif !important;word-break: break-all;" class="western" align="center">No information identified</p> </td>`
                }
                </tbody>
                </table>`;
            }
        },
        getList(level, list = []) {
            if (level) {
                return list.length > 0
                    ? `<ul style="font-family:&quot;Lato&quot;background-color:#ffffff;">
                ${list.map((ele) => `<li style="line-height:170%;"><p style="font-size:11.0pt !important;line-height:170%;font-family:&quot;Lato&quot;,sans-serif !important;" class="western" style="margin-top: 0.08in; margin-bottom: 0.08in; line-height: 170%; background:#ffffff;">${ele.entity}</p> </li>`).join("")}
                </ul>`
                    : `<p style="font-size:11.0pt !important;line-height:170%;font-family:&quot;Lato&quot;,sans-serif !important;" class="western" style="margin-top: 0.08in; margin-bottom: 0.08in; line-height: 170%; background:#ffffff;">No information identified</p>`;
            }
        },
        async handleCopyToClipboard(type, entity_identifier) {
            switch (entity_identifier) {
                case "employment":
                    entity_identifier = "company_name";
                    break;
                case "education":
                    entity_identifier = "education_institute";
                    break;
                default:
                    break;
            }
            if (["Email", "Phone Number", "Address/Location"].includes(type)) {
                type = "List";
            }
            let level = "L1";
            if (this.getCasePersonalData?.data["Due Diligence Level"]?.value) {
                const product = this.getCasePersonalData?.data["Due Diligence Level"]?.value;
                const {data} = await axios.get("/product-mapper", {
                    params: {
                        product,
                    },
                });
                if (data?.data?.product_type) level = data?.data?.product_type;
            }
            const filData = this.filteredData?.data
                ?.filter((ele) => ele.type === "card")
                ?.filter((ele1) => ele1.value.entity_identifier === entity_identifier)
                ?.map((ele2) => ele2.value)
                ?.filter((ele3) => ele3.status === "CONFIRMED");
            let table_template = this[`get${type}`](level, filData);
            const el = document.createElement("div");
            el.innerHTML = table_template;
            el.setAttribute("id", "table-template");
            el.style.fontFamily = "'Lato', serif";
            el.style.background = "#ffffff";
            el.style.position = "absolute";
            el.style.left = "-9999px";
            document.body.appendChild(el);
            let social_media = document.getElementById("table-template");
            let range, sel;
            if (document.createRange && window.getSelection) {
                range = document.createRange();
                sel = window.getSelection();
                sel.removeAllRanges();
                try {
                    range.selectNodeContents(social_media);
                    sel.addRange(range);
                } catch (e) {
                    range.selectNode(social_media);
                    sel.addRange(range);
                }
                document.execCommand("copy");
            } else if (body.createTextRange) {
                range = body.createTextRange();
                range.moveToElementText(social_media);
                range.select();
                range.execCommand("Copy");
            }
            document.body.removeChild(el);
            this.$toast.success("Copied to clipboard.");
        },
        checkClipboard(item) {
            const keys = ["Employment", "Email", "Phone Number", "Address/Location", "Education"];
            return keys.includes(item.value);
        },
    },
};
